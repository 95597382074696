$blue: #494cc1;
$blue2: #2f99da;
$red: #9d0505;
$green: #567e15;
$navy: #314667;
@mixin get-started-button {
  color: #fff;
  border-radius: 35px;
  border: solid 1px #414244;
  padding: 8px 20px;
  font-size: 14px;
  font-family: rebondG-Semi-Bold;
  background: $blue;
}
@mixin division-button {
  color: #fff;
  border-radius: 35px;
  border: solid 1px #414244;
  padding: 8px 20px;
  font-size: 14px;
  font-family: rebondG-Semi-Bold;
}
@mixin login-or-signup-button {
  background: #fff !important;
  color: #414244;
  border-radius: 32px;
  padding: 8px 20px;
  font-size: 14px;
  // font-weight: bold;
  border: none;
  border: solid 2px rgb(230, 225, 225);
  font-family: rebondG-Semi-Bold;

  &:hover {
    background: rgb(231, 231, 231) !important;
    transition: 0.5s ease;
  }
}
