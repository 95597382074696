@import "../../../variables";
@import "../../../shared-styles";

.flip-tab-container {
  height: 500px;
  border: solid 6px #eeeff5;
  border-radius: 30px;
  margin-top: 70px;
  padding: 10px 8px;
  box-shadow: 1px 21px 19px 1px rgba(231, 230, 232, 0.75);
  -webkit-box-shadow: 1px 21px 19px 1px rgba(231, 230, 232, 0.75);
  -moz-box-shadow: 1px 21px 19px 1px rgba(231, 230, 232, 0.75);
  @media screen and (max-width: 620px) {
    margin-top: 0px;
    height: 370px;
  }
  .carousel {
    position: relative;
    z-index: 0;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: fit-content;

    padding-top: 20px !important;
  }

  .carousel-indicators > button.active {
    background: $blue !important;
    outline: none;
    border: none;
    opacity: 1;
  }

  .carousel-indicators button {
    width: 89px !important;
    height: 25px !important;
    border-radius: 27px !important;
    background-color: inherit !important;
    // transition: all 0.5s linear;
    display: inline-block !important;
    color: #64656c !important;
    margin-top: 10px !important;
    @media screen and (max-width: 620px) {
      width: 40% !important;
    }
    &:active {
      background-repeat: no-repeat;
    }
    &[aria-label="1"] {
      background-image: url("../../../assets/images/1.png") !important;
      //   content: "1" !important;
      background-repeat: no-repeat !important;
      background-size: 6px !important;
      background-position: center !important;
      &.active {
        background-image: url("../../../assets/images/_1.png") !important;
      }
    }
    &[aria-label="2"] {
      background-image: url("../../../assets/images/2.png") !important;
      // content: "1" !important;
      background-repeat: no-repeat !important;
      background-size: 10px !important;
      background-position: center !important;
      &.active {
        background-image: url("../../../assets/images/_2.png") !important;
      }
    }
    &[aria-label="3"] {
      //   background-image: url("../../../assets/images/3.png") !important;
      // content: "1" !important;
      background-repeat: no-repeat !important;
      background-size: 10px !important;
      background-position: center !important;
      &.active {
        // background-image: url("../../../assets/images/_3.png") !important;
      }
    }
    &[aria-label="4"] {
      //   background-image: url("../../../assets/images/4.png") !important;
      // content: "1" !important;
      background-repeat: no-repeat !important;
      background-size: 10px !important;
      background-position: center !important;
      &.active {
        // background-image: url("../../../assets/images/_4.png") !important;
      }
    }
  }

  .carousel-indicators {
    position: relative;
    right: 0;
    border-radius: 35px;
    left: 0;
    z-index: 15;
    background: #edf0f5;
    justify-content: center;
    padding: 15px 0px 0px 0px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    list-style: none;
    outline: none;
    border: solid 1px #dbdde8;
    display: inline;
  }

  @-webkit-keyframes flip {
    from {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }

  @keyframes flip {
    from {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }

  /* entire container, keeps perspective */
  .flip-container {
    perspective: 1000;
  }

  .flip-container,
  .front,
  .back {
    width: 100%;
    height: 340px;
    img {
      @media screen and (max-width: 620px) {
        height: 200px !important;
      }
    }
  }

  /* flip speed goes here */
  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    -webkit-animation-name: flip;
    animation-name: flip;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
  }

  /* hide back of pane during swap */
  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* front pane, placed above back */
  .front {
    // background: blue;
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
  }

  /* back, initially hidden pane */
  .back {
    transform: rotateY(180deg);
    // background: green;
  }
}
