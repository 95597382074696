.careers-faq {
  $midnight: #092540;
  $clouds: #ecf0f1;
  margin-bottom: 100px;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  // Layout
  .row {
    display: flex;

    .col {
      flex: 1;
      &:last-child {
        margin-left: 1em;
      }
    }
  }
  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    font-family: recoAltSemiBold;
    font-size: 17px;

    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $midnight;
      font-weight: bold;
      cursor: pointer;
      /* Icon */
      &:hover {
        background: darken($midnight, 10%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: $midnight;
      background: white;
      font-family: rebondBook;
      transition: all 0.35s;
      padding-left: 50px;
      font-size: 20px;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $midnight;
      cursor: pointer;

      &:hover {
        background: darken($midnight, 10%);
      }
    }
  }

  // :checked
  input:checked {
    + .tab-label {
      background: darken($midnight, 10%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 100vh;
      padding: 1em;
      padding-left: 35px;
    }
  }
}
