@import "../../variables";
@import "../../shared-styles";
.careers-landing {
  height: 800px;
  background: #092540;
  .details-div {
    margin-top: 150px;
    h1 {
      color: #fff;
      font-size: 55px !important;
      margin: 30px 0;
    }
    P {
      color: #fff;
    }
    .careers-btn {
      border: none;
      background: #6ef0e3;
      text-transform: uppercase;
      padding: 7px 15px;
      border-radius: 5px;
      font-family: recoAltSemiBold;
    }

    p {
      font-size: 20px;
    }
  }
}
.apply-btn {
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-family: recoAltSemiBold;
  margin-top: 30px;
  background: $red;
  color: #fff;
}

.values {
  height: 240px;
  border: solid 1px rgb(187, 184, 184);
  margin-top: 50px;
  padding: 20px;

  border-radius: 10px;
  img {
    background-color: #edc8ff;
  }

  h3 {
    margin-top: 30px;
  }
  p {
    font-size: 20px;
    margin-top: 10px;
  }
}
