@import "../../variables";
@import "../../shared-styles";
.left {
  height: 850px;
  background: rgb(0, 19, 43);
  background: linear-gradient(
    71deg,
    rgba(0, 19, 43, 1) 80%,
    rgba(16, 82, 131, 1) 100%
  );
  padding-top: 150px;
  @media screen and (max-width: 620px) {
    height: fit-content;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  h1 {
    color: #fff;
    font-size: 50px;
    @media screen and (max-width: 620px) {
      font-size: 40px;
      text-align: center;
    }
  }
}
.about-body {
  span {
    color: grey;
  }
}
.services-div {
  background: #0b416b;
  padding-top: 90px;
  padding-bottom: 50px;
  color: #fff;
  margin-top: 80px;
}
