// @import "../../../variables";
// @import "../../../shared-styles";
// .framework-container {
//   margin-top: 100px;
//   @media screen and (max-width: 620px) {
//     margin-left: 0;
//   }
//   .frames {

//     box-shadow: 0 0 10px 10px rgb(212, 211, 211);
//     border-radius: 50%;
//     text-align: center;
//     background: #fff;
//     margin: 40px 60px;
//   }
//   .small-frames {
//     height: 80px;
//     width: 80px;
//     padding-top: 15px;
//     h6 {
//       font-size: 12px;
//     }
//     img {
//       border-radius: 10px;
//     }
//   }
//   .big-frames {
//     height: 200px;
//     width: 200px;
//     padding-top: 80px;
//   }
//   .framework-btns {
//     button {
//       border: solid 1px #e7e7e7;
//       border-radius: 35px;
//       background: #fff;
//       color: #000;
//       padding: 20px 22px;
//       margin: 20px;
//       font-family: rebondG-Bold;
//       &:hover {
//         background: $blue;
//         color: #fff;
//         transition: 0.5s ease;
//       }
//     }
//   }
//   .first-row {
//     margin-top: -120px;
//   }
//   .s6 {
//     margin-top: -50px;
//   }
//   .s11 {
//     margin-top: -50px;
//   }
// }
// .box {
//   height: 50px;
//   width: 50px;
//   margin: 5px 5px;
// }
// .zoom-in-out-box {
//   animation: zoom-in 1s ease;
// }

// @keyframes zoom-in {
//   0% {
//     transform: scale(1, 1);
//   }
//   50% {
//     transform: scale(1.5, 1.5);
//   }
//   100% {
//     transform: scale(1.5, 1.5) !important;
//   }
// }
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500&family=Petemoss&display=swap");

* {
  box-sizing: border-box;
  // font-family: "Cinzel", serif;
}

// body {
// 	margin: 0;
// 	padding: 0;
// }
.frameworks-div {
  .framework-cova {
    display: flex;
    justify-content: center;
    background-color: red;
  }

  .book {
    --book-height: 125vh;
    --book-ratio: 1.4;
  }

  .book > div {
    height: var(--book-height);
    width: calc(var(--book-height) / var(--book-ratio));
    overflow: auto;
    background-color: #0a0a0a;
    transform: scale(0.9);
    border-radius: 6px;
    transform-origin: left;
  }

  .book-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 9;
    text-align: center;
    background: linear-gradient(135deg, black 25%, transparent 25%) -50px 0,
      linear-gradient(225deg, black 25%, transparent 25%) -50px 0,
      linear-gradient(315deg, black 25%, transparent 25%),
      linear-gradient(45deg, black 25%, transparent 25%);
    background-size: 2em 2em;
    background-color: #232323;
    color: white;
    transition: transform 2s;
  }

  .book-cover::before {
    content: "";
    position: absolute;
    width: 20px;
    right: 20px;
    top: 0;
    bottom: 0;
    background-color: rgb(12, 12, 87);
  }

  h1 {
    font-size: 98px;
    font-weight: 300;
    color: #605cb8;
  }

  h2 {
    font-size: 16px;
  }

  .separator {
    --separator-size: 8px;
    width: var(--separator-size);
    height: var(--separator-size);
    background-color: #dbd75d;
    margin: 50px auto 60px auto;
    border-radius: 50%;
    position: relative;
  }

  .separator::after,
  .separator::before {
    content: "";
    position: absolute;
    width: 12px;
    background-color: white;
    height: 2px;
    top: calc(50% - 1px);
  }

  .separator::after {
    left: 15px;
  }

  .separator::before {
    right: 15px;
  }

  .book-content {
    padding: 50px 10px;
    transform: scale(0.9) translateY(30px);
    background-color: rgb(243, 234, 234) !important;
    transition: all 0.3s 1s;
    img {
      border-radius: 20px;
    }
    .frames {
      // height: 300px;
      // background: black;
    }
  }

  .book-content h3,
  .book-content p {
    opacity: 0;
    transition: all 0.3s 0.3s;
  }

  h3 {
    padding: 30px;
  }

  p {
    padding: 0px 30px 10px 30px;
    text-align: justify;
    font-size: 14px;
  }

  .book-cover > div {
    transition: opacity 0s 0.6s;
  }

  .book:hover > .book-cover {
    transform: rotateY(180deg) scale(0.9);
  }

  .book:hover > .book-cover > div {
    opacity: 0;
  }

  .book:hover > .book-content {
    transform: scale(0.9) translateY(0px);
  }

  .book:hover > .book-content h3,
  .book:hover > .book-content p {
    opacity: 1;
  }
}
