@import "../../../variables";
@import "../../../shared-styles";

.dashboard-accordion-container {
  margin-bottom: 50px;
  // margin-top: 150px;
  .accordion-body {
    background: #fff;
    box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    margin-bottom: 30px;
    margin-top: 30px !important;
    padding-top: 0 !important;
    border-radius: 10px !important;
  }
  .accordion {
    margin-top: 30px;
    margin-left: 30px;
    @media screen and (max-width: 620px) {
      margin-top: 0px;
      margin-left: 0px;
    }
  }
  .accordion-button:not(.collapsed) {
    background: #fff !important;
    border-top-right-radius: 30px !important;
    border-top-left-radius: 30px !important;
    padding-bottom: 0 !important;
    box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
  }
  .accordion-item {
    border: none !important;
  }

  .accordion-button:focus {
    border-color: #fff !important;
    outline: none !important;
    box-shadow: none !important;

    // border-top: solid 2px rgb(155, 154, 154) !important;
  }
  .accordion-button {
    background: #f9fafb !important;
    margin-top: 0px;
    padding-top: 20px !important;
    // padding-bottom: 10px !important;
    padding-left: 30px !important;
  }
  .accordion-button::after {
    // background-image: url("../../../assets/images/add-circle.svg") !important;
    content: "" !important;
    background-position: center;
    background-size: 100%;
    height: 10px;
    width: 10px;
  }
  .accordion-button:not(.collapsed)::after {
    // background-image: url("../../../assets/images/sub-circle.svg") !important;

    content: "" !important;
  }
  .accordion-button:not(.collapsed) {
    color: #161617 !important;
    box-shadow: none !important;
    border: none !important;

    // border-top: solid 2px rgb(155, 154, 154) !important;
  }
  .accordion-header {
    h6 {
      color: #161617;
      span {
        color: $blue;
        font-size: 25px;
        margin-right: 10px;
      }
    }
  }
  .accordion-item:first-of-type .accordion-button {
    // border-radius: 0 !important;
  }
  .tech-img {
    box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 19px 17px 10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
  }
}

.accordion-button::after {
  background-image: url("../../../assets/images/add-circle2.svg") !important;
  content: "" !important;
  background-position: center;
  background-size: 100%;
  height: 30px !important;
  width: 30px !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../../../assets/images/blue-minus.svg") !important;

  content: "" !important;
}
.dashboard-display {
  img {
    width: 100%;
    height: 100%;
  }
}
