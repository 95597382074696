@import "../../variables";
@import "../../shared-styles";

.hire-form {
  background: #f5f8ff;
  padding: 50px;
  margin-top: 100px;
  height: fit-content;
  .intro-div {
    background: $navy;
    padding: 20px;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 80px;
  }
  .myinput:hover + .mydisplay {
    border-color: #00b3ee;
  }

  .myinput:checked + .mydisplay {
    background-size: cover;
    background-image: url("../../assets/images/tick.png");
    background-color: $navy;
    border-color: #00b3ee;
  }
  .myinput {
    display: none;
  }

  .myinput + .mydisplay {
    width: 20px;
    height: 20px;
    border: 3px solid darkgray;
    border-radius: 6px;
    box-sizing: border-box;
    margin-right: 10px;
  }

  .mylabel {
    display: flex;
  }

  h6 {
    margin-top: 20px;
  }
  .app-btn {
    @include division-button;
    background: #3099da;
    border: none;
  }
  .radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio-container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}
