@import "../../variables";
@import "../../shared-styles";
.tech-logo {
  margin-top: -50px;
}
.navigation {
  position: fixed;
  z-index: 1000;
  .active {
    color: $red !important;
    button {
      color: $red !important;
    }
  }
}
.tech-nav {
  display: flex;
  padding-top: 20px;
  position: relative;
  z-index: 2 !important;
  padding-bottom: 0;
  height: 70px;
}
.web-nav {
  a {
    text-decoration: none !important;
    font-family: rebondG-Bold;
  }
}
.tech-logo-div {
  flex-grow: 1 !important;
  margin-top: 30px;
}
.tech-links {
  color: #fff;
  text-decoration: none;
  font-family: rebondG-Bold;
  margin: 20px !important;

  &:hover {
    color: #fff;
  }
}
.cool-link::after {
  content: "";
  display: block;
  width: 0;
  margin-left: 11px;
  height: 4px;
  background: $red;
  transition: width 0.3s;
  position: relative;
  z-index: 300000000000000;
}

.cool-link:hover::after {
  width: 100%;
}

.menu-dropdown {
  width: 310px;
  padding: 15px 0;
  position: absolute;
  background: #fff;
  margin-top: -20px;
  z-index: 100;
  transition: 0.5s padding, 0.5s background;
  box-shadow: 0 0 2px 2px rgb(225, 222, 222);
  border-radius: 5px;
  .links {
    color: black;
    font-family: recoAltSemiBold;
    text-decoration: none;
    &:hover {
      background: $red;
      color: #fff;
    }
  }
}

.menu-dropdown:after {
  content: "";
  display: block;
  height: 0;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 101;
  transition: 0.5s border-top;
}

.menu:not(:hover) > .menu-dropdown {
  padding: 0;
  box-shadow: none;
  z-index: 99;
  // display: none;
}

.menu-dropdown > * {
  overflow: hidden;
  height: fit-content;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0);
  // white-space: nowrap;
  transition: 0.5s height cubic-bezier(0.73, 0.32, 0.34, 1.5),
    0.5s padding cubic-bezier(0.73, 0.32, 0.34, 1.5),
    0.5s margin cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s 0.2s color,
    0.2s background-color;
}

.menu-dropdown > *:hover {
  // background: rgba(0, 0, 0, 0.1);
}

.menu:not(:hover) > .menu-dropdown > * {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  color: rgba(25, 25, 25, 0);
  transition: 0.5s 0.1s height, 0.5s 0.1s padding, 0.5s 0.1s margin, 0.3s color,
    0.6s visibility;
  z-index: 99;
}

.hList > * {
  float: left;
}

.hList > * + * {
  margin-left: 0;
}
.menu-links {
  padding: 10px 5px;
}
.web-view {
  @media screen and (max-width: 620px) {
    display: none;
  }
}

.mobile-navigation {
  .active {
    color: $red !important;

    button {
      color: $red !important;
    }
  }
  @media screen and (min-width: 620px) {
    display: none;
  }
  a {
    font-family: recoAltSemiBold;
    text-decoration: none;
  }
  height: 0;
  .menu-navs {
    button {
      margin-top: 10px;
      margin-bottom: 10px;
      background: #f2f2f2 !important;
    }
  }
  .phone {
    background-color: transparent;

    height: 400px;
    overflow: hidden;

    width: 100%;
  }

  .menu {
    align-items: center;
    background-color: transparent !important;
    clip-path: polygon(81.05% 9.1%, 92% 9.1%, 92% 10.1%, 81.05% 10.1%);
    color: #f2f2f2;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    font-size: 28px;
    height: 80%;
    padding: 0 20px;
    position: fixed;

    top: -1%;
    right: 4%;
    transition: clip-path 400ms cubic-bezier(0.4, 0, 0.2, 1),
      background-color 400ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 90%;
    z-index: 51;
  }
  .active3 .menu {
    background-color: #3099da !important;
    clip-path: polygon(101% -1%, 101% 101%, -1% 101%, -1% -1%);
    width: 100% !important;
    height: 100%;
    // position: fixed;
    right: 0%;
    z-index: 150;
    overflow: scroll;
    opacity: 1;
  }
  .options {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    transform: scale(0.8);
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
  }
  .option {
    cursor: pointer;
    margin: 8px 0;
  }
  .option.selected {
    color: #a02f56;
  }
  .active3 .options {
    transform: scale(1);
  }
  .x {
    pointer-events: none;
    position: fixed !important;
    top: 2%;
    margin-left: 38%;
    z-index: 2001;
  }
  .top-bars {
    stroke: #fff;
    margin-left: 80px !important;
    transition: stroke 400ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .active3 .top-bars {
    stroke: #fff !important;
  }
  .bar {
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .bar1 {
    transform-origin: 190px 20.5px;
  }
  .bar2 {
    transform-origin: 190px 28.5px;
  }
  .active3 .bar1 {
    transform: translateY(8.6px) rotate(45deg);
  }
  .active3 .bar2 {
    transform: rotate(-45deg);
  }
  .menu-click-area {
    cursor: pointer;
    height: 70px;
    opacity: 0.3;
    position: fixed;
    right: 0px;
    top: 60px;
    width: 70px;
    z-index: 3001 !important;
  }
}

.active2 {
  color: $red !important;
}
