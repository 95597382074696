@import "../../variables";
@import "../../shared-styles";
.image-container {
  @media screen and (max-width: 620px) {
    height: fit-content;
    background-color: $blue;
    margin-bottom: 0px;
  }
  .viddeo {
    @media screen and (max-width: 620px) {
      height: 800px;
      transform: translateX(0%) translateY(0%);
      object-fit: cover;
    }
  }
}
// #home-top-video:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
//   z-index: 1;
//   background-image: linear-gradient(90deg, #004f9d 35%, rgba(0, 7, 13, 0) 100%);
//   opacity: 0.9;
// }

#home-top-video {
  left: 0%;
  margin-top: -9% !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
  // position: fixed;
  z-index: -1;
}

.frameworks-div {
}

.swipes {
  padding-left: 50px;
  @media screen and (max-width: 620px) {
    padding-left: 0px;
    overflow: hidden;
  }
  .swiper {
    width: 100%;
    height: 400px;
    // margin-top: 250px;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .swiper-slide:nth-child(1n) {
    // background-color: rgb(206, 17, 17);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(2n) {
    // background-color: rgb(0, 140, 255);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(3n) {
    // background-color: rgb(10, 184, 111);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(4n) {
    // background-color: rgb(211, 122, 7);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(5n) {
    // background-color: rgb(118, 163, 12);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(6n) {
    // background-color: rgb(180, 10, 47);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(7n) {
    // background-color: rgb(35, 99, 19);
    background: #fff !important;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(8n) {
    // background-color: rgb(0, 68, 255);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(9n) {
    // background-color: rgb(218, 12, 218);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(10n) {
    // background-color: rgb(54, 94, 77);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }
  .swipper-container {
    margin-bottom: 150px !important;
  }

  .detail-slide {
    position: relative;
    margin-left: 500px;
    z-index: 10000;
  }
}
.swipes2 {
  margin-top: 180px;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
  p {
    font-size: 25px;
    font-family: recoAltRegular;
    line-height: 32px;
    margin-bottom: 60px;
    margin-top: -40px;
  }

  .mySwiper {
    h1 {
      font-size: 80px !important;
      color: $blue;
      margin-top: 40px;
    }
  }
}

.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  // top: 230px !important;
  margin-bottom: -130px !important;
}

.swiper-pagination-bullet-active {
  color: #fff !important;
  background-color: $blue !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  top: 120%;
}
.services-container {
  .detail-section {
    margin-top: 200px;
    h1 {
      color: $navy;
    }
  }
}
