@import "../../variables";
@import "../../shared-styles";

.product-container2 {
  position: relative;

  img {
    height: 800px;
    @media screen and (max-width: 620px) {
      height: 400px;
    }
  }
  .product-overlay2 {
    position: absolute;
    // transform: translate(0%, -50%);
    top: 40%;
    h1,
    h4 {
      color: #fff;
    }
    h1 {
      font-size: 70px;
      color: #fff;
      text-shadow: 5px 5px 10px black;
      @media screen and (max-width: 620px) {
        font-size: 40px;
      }
    }
    h4 {
      font-weight: bold;
    }
  }
}
.portfolio-section {
  padding-bottom: 120px;
  padding-top: 80px;
  .portfolio-paragraph {
    text-align: justify;
  }
}
.portfolio-slide {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    71deg,
    rgba(255, 255, 255, 1) 3%,
    rgba(249, 229, 229, 1) 47%,
    rgba(255, 255, 255, 1) 98%
  );
  a {
    position: relative;
    z-index: 1000;
    color: #3099da;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.preview {
  height: 500px;
  @media screen and (max-width: 620px) {
    height: 400px;
  }
}
.carousel-control-next-icon {
  background-image: url("../../assets/images/next.png");
  margin-left: 150px !important;
  position: relative !important;
  margin-top: -60px !important;
  border-radius: 100%;
}
.carousel-control-prev-icon {
  background-image: url("../../assets/images/prev.png");
  margin-right: 150px !important;
  margin-top: -60px !important;
}
.carousel-indicators button {
  background: #3099da !important;
  border: solid 1px #707070 !important;
  border-radius: 50% !important;
  height: 7px !important;
  width: 7px !important;
  margin-top: 400px !important;
}
.port-para {
  padding: 280px 0 80px 0;
  @media screen and (max-width: 620px) {
    padding: 0px 0;
    margin-top: -70px;
  }
}

.swipes {
  @media screen and (max-width: 620px) {
    padding-left: 0px;
    overflow: hidden;
  }
  .swiper {
    width: 100%;
    height: 400px;
    margin-top: 250px;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .swiper-slide:nth-child(1n) {
    // background-color: rgb(206, 17, 17);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(2n) {
    // background-color: rgb(0, 140, 255);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(3n) {
    // background-color: rgb(10, 184, 111);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(4n) {
    // background-color: rgb(211, 122, 7);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(5n) {
    // background-color: rgb(118, 163, 12);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(6n) {
    // background-color: rgb(180, 10, 47);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(7n) {
    // background-color: rgb(35, 99, 19);
    background: #fff !important;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(8n) {
    // background-color: rgb(0, 68, 255);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(9n) {
    // background-color: rgb(218, 12, 218);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }

  .swiper-slide:nth-child(10n) {
    // background-color: rgb(54, 94, 77);
    background: #fff;
    color: black;
    box-shadow: 0 0 3px 3px rgb(179, 176, 176);
  }
  .swipper-container {
    margin-bottom: 150px !important;
  }

  .detail-slide {
    position: relative;
    margin-left: 500px;
    z-index: 10000;
  }
}
.swipes2 {
  // margin-top: 180px;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
  p {
    font-size: 25px;
    font-family: recoAltRegular;
    line-height: 32px;
    margin-bottom: 60px;
    margin-top: -40px;
  }

  .mySwiper {
    h1 {
      font-size: 80px !important;
      color: $blue;
      margin-top: 40px;
    }
  }
}

.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  // top: 230px !important;
  margin-bottom: -130px !important;
}

.swiper-pagination-bullet-active {
  color: #fff !important;
  background-color: $blue !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  top: 120%;
}
.services-container {
  .detail-section {
    margin-top: 200px;
    h1 {
      color: $navy;
    }
  }
}
