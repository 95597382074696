@import "../../variables";
@import "../../shared-styles";
.contact-div {
  position: relative;
  padding-top: 60px !important;
}
.form-div {
  position: absolute;
  transform: translate(-0%, -50%);
  top: 70%;
  background: #fff;
  height: fit-content-;
  //   padding: 30px;
  box-shadow: 0 0 3px 3px rgb(215, 215, 215);
  border-radius: 20px;
  input {
    height: 40px;
    width: 100%;
    padding-left: 20px;
    border: solid 1px #d6d6d6;
    border-radius: 10px;
    margin-top: 20px;
    font-family: helveticaR;
  }
  textarea {
    width: 100%;
    padding-left: 20px;
    border: solid 1px #d6d6d6;
    border-radius: 10px;
    margin-top: 20px;
    height: 100px;
    font-family: helveticaR;
  }
  .form-heading {
    height: fit-content;
    box-shadow: 0 0 3px 3px rgb(215, 215, 215);
    position: relative;
    width: 100%;
    @media screen and (max-width: 620px) {
      // margin-top: 100%;
    }
    img {
      //   border: solid 1px red;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    .form-write-up {
      position: absolute;
      top: 50%;
      transform: translate(-0%, -50%);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      box-shadow: 0 0 3px 3px rgb(215, 215, 215);
      background: rgba(12, 12, 12, 0.7);
      color: #fff;
      height: 100%;
      @media screen and (max-width: 620px) {
        height: fit-content;
        top: 80%;
        margin-bottom: 60px;
      }
      h6 {
        color: #fff;
      }
    }
  }
  .form-body {
    padding: 30px;

    @media screen and (max-width: 620px) {
      margin-top: 50px;
    }
  }
}
.enquiry-form {
  border-radius: 10px;
  //   padding: 5px;
  h6 {
    margin-top: 10px;
  }
  button {
    @include division-button;
    background: $blue2;
    border: none;
  }
}
.form-div {
  @media screen and (max-width: 620px) {
    margin-top: 100px;
  }
}
