@media screen and (min-width: 620px) {
  .flexy {
    display: flex;
  }
}
@media screen and (max-width: 620px) {
  .flexyM {
    display: flex;
  }
}
@font-face {
  font-family: rebondG;
  src: url(./assets/fonts/Rebond_font/RebondG.otf);
}
@font-face {
  font-family: rebondG-Bold;
  src: url(./assets/fonts/Rebond_font/RebondG-Bold.otf);
}
@font-face {
  font-family: rebondG-Semi-Bold;
  src: url(./assets/fonts/Rebond_font/Rebond-semibold.otf);
}
@font-face {
  font-family: rebondG-Medium;
  src: url(./assets/fonts/Rebond_font/RebondGrotesqueMedium.otf);
}
@font-face {
  font-family: rebondBook;
  src: url(./assets/fonts/Rebond_font/RebondG-Book.ttf);
}
@font-face {
  font-family: rebondLight;
  src: url(./assets/fonts/Rebond_font/RebondGLight.otf);
}

@font-face {
  font-family: recoMedium;
  src: url(./assets/fonts/Recoleto-font/Recoleta-Medium.ttf);
}
@font-face {
  font-family: recoSemiBold;
  src: url(./assets/fonts/Recoleto-font/Recoleta-SemiBold.ttf);
}
@font-face {
  font-family: recoAltSemiBold;
  src: url(./assets/fonts/Recoleto-font/RecoletaAlt-SemiBold.ttf);
}
@font-face {
  font-family: recoAltMedium;
  src: url(./assets/fonts/Recoleto-font/RecoletaAlt-Medium.ttf);
}
@font-face {
  font-family: recoAltRegular;
  src: url(./assets/fonts/Recoleto-font/RecoletaAlt-Regular.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: recoAltSemiBold;
  color: #314667;
}
.reco-alt {
  font-family: recoAltMedium;
}
p {
  font-family: rebondBook;
  font-size: 16px;
  color: rgb(101, 101, 101);
}
span {
  font-family: rebondBook;
}
ul li {
  font-family: rebondBook;
  font-size: 18px;
  color: rgb(101, 101, 101);
}
ol li {
  color: rgb(101, 101, 101);
}
