@import "../../variables";
@import "../../shared-styles";
.hire-container {
  margin-top: 100px;
  h1 {
    color: $navy;
    font-size: 60px;
    margin-bottom: 30px;
  }
  .hire-btn {
    margin-top: 30px;
    background: #2f99da;
    color: #fff;
    border: none;
    padding: 7px 15px;
    border-radius: 20px;
    font-family: rebondG-Semi-Bold;
  }
}
.why-hexxondiv {
  margin-top: 100px;

  background: #f8f9f9;
  padding-top: 60px;
  padding-bottom: 90px;
  p {
    margin: 30px 0;
    font-size: 17px;
  }
  .column {
    border-left: solid 2px #e7e7e7;
    padding: 10px 20px;
    h2 {
      margin-top: 60px;
    }
  }
}
.hire-steps {
  margin-top: 150px;
  h1 {
    margin-bottom: 60px;
  }
  .circle {
    height: 20px;
    width: 20px;
    border: solid 3px black;
    border-radius: 100%;
    margin-bottom: 40px;
  }
  .dash {
    border-bottom: solid 2px red;
  }
}
