@import "../../variables";
@import "../../shared-styles";
.home-container {
  padding-top: 300px;
  padding-bottom: 300px;
  background: url("../../assets/images/landing2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  // background-position: center;
  color: #fff;
  // margin-top: -9% !important;
  @media screen and (max-width: 620px) {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 200px;
  }
  h1 {
    color: #fff;
    margin-bottom: 30px;
  }
  button {
    margin-top: 60px;
    @include get-started-button;
  }
  .second-div {
    @media screen and (max-width: 620px) {
      margin-top: 80px;
    }
  }
}
.partners {
  img {
    width: 80%;
    @media screen and (max-width: 620px) {
      width: 30%;
      margin-top: 50px;
    }
  }
}
.divisions-div {
  padding-top: 100px;
  padding-bottom: 50px;
  margin-top: 100px;
  background: #0b416b;
}
.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #494cc1 0%,
    #44107a 29%,
    #ff1361 67%,
    #1be26b 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 60px;
  @media screen and (max-width: 620px) {
    font-size: 50px;
  }
}
.animate-charcter2 {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #567e15 0%,
    #79eb1b 29%,
    #567e15 67%,
    #1be26b 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 50px;

  @media screen and (min-width: 620px) {
    display: none;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.content {
  position: relative;
  margin-top: 70px;
  margin-bottom: 190px;

  @media screen and (max-width: 620px) {
    margin-bottom: 50px;
  }
}

.content h2 {
  color: #fff;
  font-size: 9em;
  position: absolute;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 620px) {
    display: none;
  }
}

.content h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px $green;
}

.content h2:nth-child(2) {
  color: $green;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
.division-btn {
  @include division-button;
}
.tech {
  background-image: linear-gradient(
    -225deg,
    #494cc1 0%,
    #44107a 29%,
    #ff1361 67%,
    #1be26b 100%
  );
}
.agro {
  background: $green;
}
.products {
  .link {
    @include get-started-button;
    text-decoration: none;
    font-size: 20px;
  }
}
.stats-div {
  height: 400px;
  background: #092540;
  margin-top: 100px;
  color: #fff;
  border-radius: 30px;
  padding-top: 100px;
  margin-bottom: 100px;
  @media screen and (max-width: 620px) {
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  h1 {
    color: #fff;
  }
  .stat {
    h1 {
      font-size: 60px !important;
      margin-top: 40px;
      font-weight: bold;
    }
  }
}
